<template>
  <div class="search">
    <div class="search-all">
      <div class="search-box flex-between">
        <div class="left" @click="$router.go(-1)"></div>
        <div class="center ellipsis">好片大赏</div>
        <div
          @click="
            $router.replace({
              path: '/pastTag',
              query: { id: $route.query.id },
            })
          "
          class="right"
        >
          往期主题
        </div>
      </div>
      <div class="all">
        <AdvSwiper :advList="goodAd" />
        <div class="section">
          <pullrefresh
            :disabled="true"
            @onLoad="onLoad"
            ref="pull"
            v-show="videoList.length > 0"
          >
            <div class="video-list">
              <div v-for="item in videoList" :key="item.id">
                <div v-if="advList.length > 0 && item.adv" class="l_adv">
                  <AdvSwiper :advList="item.adv" />
                </div>
                <videoListMore :item="item" v-else />
              </div>
            </div>
          </pullrefresh>
          <van-loading
            type="spinner"
            color="#f21313"
            v-show="videoList && videoList.length == 0 && !noData"
          />
          <nodata v-show="noData" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import videoListMore from "../widget/videoListMore.vue";
import "swiper/dist/css/swiper.css";
import AdvSwiper from "@/components/AdvSwiper/index.vue";
import pullrefresh from "@/components/PullRefresh/index.vue";
import { tagList } from "@/api/app.js";
import { getAdItem, AdType } from "@/utils/getAdv.js";
export default {
  components: {
    videoListMore,
    AdvSwiper,
    pullrefresh,
  },
  data() {
    return {
      videoList: [], //视频列表
      oldVideoList: [],
      pageNum: 1, //页码
      noData: false, //暂无数据
      goodAd: [], //首页推荐广告
      swiperOption: {
        observeParents: true,
        observe: true,
        // loop:true,
        autoplay: {
          delay: 10000,
          disableOnInteraction: false,
        },
        //显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许点击分页跳转
        },
      },
      advList: [],
    };
  },
  created() {
    this.getList();
    this.goodAd = getAdItem(AdType.goodVideoBanner);
    this.advList = getAdItem(AdType.content2x2);
  },
  methods: {
    async getList() {
      //通过接口得到视频列表
      let req = {
        id: this.$route.query.id,
        isVip: true,
        pageNum: this.pageNum,
        pageSize: 10,
      };
      let ret = await this.$Api(tagList, req);
      if (!this.$refs.pull) return;
      this.$refs.pull.loading = false;
      if (ret.code == 200) {
        let list = ret.data.mediaInfos ? ret.data.mediaInfos : [];
        if (this.advList.length > 0) {
          this.oldVideoList = this.oldVideoList.concat(list);
          let advNum = Math.floor(this.oldVideoList.length / 4);
          this.videoList = JSON.parse(JSON.stringify(this.oldVideoList));
          let advLength = this.advList.length;
          for (let i = 0; i < advNum; i++) {
            let advItem = this.advList[i % advLength];
            this.videoList.splice(5 * i + 4, 0, { adv: [advItem] });
          }
        } else {
          this.videoList = this.videoList.concat(list);
        }

        if (list.length < 10) {
          this.$refs.pull.finished = true;
        } else {
          this.$refs.pull.finished = false;
        }
        if (this.pageNum == 1 && list.length == 0) {
          this.noData = true;
        }
      }
    },
    onLoad() {
      this.pageNum++;
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
.search-all {
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.search-box {
  padding: 0 16px;
  height: 52px;
  align-items: center;
  background: $vermillion;
}
.left {
  width: 22px;
  height: 22px;
  background: url("../../assets/png/back_icon.png") center center no-repeat;
  background-size: 100%;
  margin-right: 34px;
}
.center {
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
}
.right {
  width: 56px;
  color: #ffffff;
  font-size: 14px;
}
.all {
  height: calc(100% - 52px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
}
.section {
  padding: 10px 5px 30px;
}
.lunbo {
  height: 150px;
  position: relative;
}
.swiper-all {
  width: 100%;
  height: 100%;
}
.ad-img {
  width: 100%;
  height: 100%;
}
.swiper-pagination {
  right: 16px;
  bottom: 10px;
}
.swiper-pagination /deep/ .swiper-pagination-bullet {
  margin-left: 6px;
  width: 6px;
  height: 6px;
}
.swiper-pagination /deep/ .swiper-pagination-bullet-active {
  width: 24px;
  background: #ffffff;
  border-radius: 4px;
}
.video-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.l_adv {
  width: 178px;
  height: 118px;
  padding: 6px 0 6px 6px;
  box-sizing: border-box;
  overflow: hidden;
  .adv {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    overflow: hidden;
  }
  /deep/ .swiper-container,
  /deep/ .swiperSlide {
    width: 100%;
    height: 100%;
  }
}
</style>
